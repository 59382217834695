import { mapHelper } from 'common/utils'
// 状态
const belongType = [
  {
    text: '集团',
    value: 100
  },
  {
    text: '项目',
    value: 101
  },
  {
    text: '公司',
    value: 102
  },
  {
    text: '平台',
    value: 106
  }
]

const {
  map: belongTypeMap,
  setOps: setBelongTypeOps
} = mapHelper.setMap(belongType)
export {
  belongTypeMap,
  setBelongTypeOps
}
