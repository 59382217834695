<template>
  <div class="orgLabelList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增标签" permission="addParentLabel" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标签名称" v-model="searchParams.tagName"></v-input>
        <v-select label="标签层级" v-model="searchParams.tagLevel" :options="tagLevelOps"></v-select>
        <v-select2 label="上级标签" :disabled="searchParams.tagLevel === 1" v-model="searchParams.parentId" v-bind="tagParams"></v-select2>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select label="归属层级" v-model="searchParams.belongType" :options="belongTypeOps"></v-select>
        <v-input label="创建人 " v-model="searchParams.inuser"></v-input>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime" type="rangedatetimer"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.tagLevel == 1" text="编辑标签" type="text" permission="updateParentLabel" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.tagLevel == 2" text="编辑标签" type="text" permission="updateChildLabel" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.tagLevel == 1" text="编辑属性" type="text" permission="updateLabelAttr" @click="editAttr(scope.row.id, scope.row.tagName)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getSuperiorTagUrl, getDropDownSearchTagUrl } from './api'
import { belongTypeMap, setBelongTypeOps } from './map'

export default {
  name: 'OrgLabelList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      tagLevelOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '一级',
          value: 1
        }, {
          text: '二级',
          value: 2
        }
      ],
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '正常',
          value: 1
        }, {
          text: '关闭',
          value: 2
        }
      ],
      belongTypeOps: setBelongTypeOps(1),
      tagParams: {
        searchUrl: getSuperiorTagUrl,
        backSearchUrl: getDropDownSearchTagUrl,
        request: {
          text: 'tagName',
          value: 'id'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      searchParams: {
        tagName: '',
        tagLevel: undefined,
        parentId: undefined,
        status: undefined,
        belongType: undefined,
        inuser: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'tagName',
          label: '标签名称'
        },
        {
          prop: 'tagLevel',
          label: '标签层级'
        },
        {
          prop: 'parentName',
          label: '上级标签'
        },
        {
          prop: 'statusName',
          label: '状态'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'belongType',
          label: '归属层级',
          formatter: (val) => belongTypeMap[val.belongType]
        },
        {
          prop: 'belongName',
          label: '标签归属'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    updateStatus (ids, batchURL) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        ids: _ids,
        dataObject: _ids
      }
      _this.$axios.post(batchURL, postData).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    create () {
      this.$router.push({
        name: 'orgLabelForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'orgLabelForm',
        query: {
          id: row.id
        }
      })
    },
    editAttr (id, tagName) {
      this.$router.push({
        name: 'orgLabelAttrForm',
        query: {
          id,
          tagName
        }
      })
    }
  }
}
</script>
